/*
    User can type a single email or string of emails
    Multiple emails can be separated by space or comma
    Email can be in any case
    Email can be in formats:
        {email}
        {<email>}
        {name <email>}
        {email <email>}
        {name (email)}
        {email (email)}
        {(email)}
    RFC 5322 compliant characters are only allowed
*/

/*
    Regex referred by Chromium for <input type="email">
    https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/renderer/core/html/forms/email_input_type.cc;l=46;bpv=0;bpt=1
    https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
*/

//TBD Refactor core regex / remove duplication
const EMAIL_REGEX_SINGLE =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const EMAIL_REGEX_MULTIPLE =
	/[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/g;

// const VALID_DOMAINS = new Set([
// 	'gmail.com',
// 	'yahoo.com',
// 	'hotmail.com',
// 	'outlook.com',
// 	'aol.com',
// 	'icloud.com',
// 	'mac.com',
// 	'me.com',
// 	'protonmail.com',
// 	'pm.me',
// 	'zoho.com',
// 	'mail.com',
// 	'gmx.com',
// 	'tutanota.com',
// 	'fastmail.com',
// 	'disroot.org',
// 	'riseup.net',
// 	'posteo.de'
// ]);
const hasMXRecord = async () => {
	//TBD: Lookup MX record to check email address exists
	//Check VALID_DOMAINS before making lookup call
	return true;
};

const isValidEmail = (email) => {
	return EMAIL_REGEX_SINGLE.test(email);
};

const parseEmails = (str) => {
	return str.match(EMAIL_REGEX_MULTIPLE);
};

export { parseEmails, isValidEmail, hasMXRecord };

/*
Uncomment for tests
In this directory, run `npx mocha email-helper.js`
Email test cases from https://gist.github.com/cjaoude/fd9910626629b53c4d25
const VALID_EMAILS = [
    'email@example.com',
    'firstname.lastname@example.com',
    'email@subdomain.example.com',
    'firstname+lastname@example.com',
    '1234567890@example.com',
    'email@example-one.com',
    '_______@example.com',
    'email@example.name',
    'email@example.museum',
    'email@example.co.jp',
    'firstname-lastname@example.com',
]
const INVALID_EMAILS = [
    'plainaddress',
    '#@%^%#$@#$@#.com',
    '@example.com',
    'Joe Smith <email@example.com>',
    'email.example.com',
    'email@example@example.com',
    'あいうえお@example.com',
    'email@example.com (Joe Smith)',
    'email@-example.com',
    'email@example..com',
]
const EMAIL_LIST = `
    email@example.com
    email.address@example.com,
    john smith <email2@example.com>
    john smith <email@example.com>
    john <email@>,
    email3@example.com <email3@example.com>,
    email <
    email4@example.com (Joe Smith)
    Joe Smith (email5@example.com),
    (email6@example.com)
    `
const EMAIL_LIST_EXPECTED = [
    'email@example.com',
    'email.address@example.com',
    'email2@example.com',
    'email3@example.com',
    'email4@example.com',
    'email5@example.com',
    'email6@example.com'
]

it('isValidEmail', () => {
    for(const email of VALID_EMAILS) {
        const res = isValidEmail(email);
        if(res === false)
            throw new Error(`${email} should be invalid`);
    }
    for(const email of INVALID_EMAILS) {
        const res = isValidEmail(email);
        if(res === true)
            throw new Error(`${email} should be valid`);
    }
});

it('parseEmails', () => {
    const res = parseEmails(EMAIL_LIST);
    for (const email of res) {
        if (!EMAIL_LIST_EXPECTED.includes(email)) {
            throw new Error(`Parsing failed for ${email}`);
        }
    }
})
*/
