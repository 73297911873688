<script>
	import { _, locale } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { getManagedDicsovery, postLinkProvider, postLoginProvider } from '../utils/api-calls';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import LoginProvider from './LoginProvider.svelte';
	import VerifyEmail from './VerifyEmail.svelte';
	import MailIcon from './icon/MailIcon.svelte';
	import { isValidEmail } from '../utils/email-helper';
	import { showSpinner } from '../stores';
	import { createEventDispatcher } from 'svelte';
	import logins from '../../../../svr/providers/logins.json';

	export let login = false;
	export let css = '';
	export let accountSelected = undefined;

	let value = '';
	$: domain = value?.split('@')[1];
	let showManagedNotFoundErrorText = false;
	let managedProviderFound = false;
	let checkManagedAjax = false;
	let isPublicEmailDomain = false;
	let timer;
	let discoRes;

	const dispatch = createEventDispatcher();

	async function handleInput() {
		//tbd cleanup
		checkManagedAjax = showManagedNotFoundErrorText = isPublicEmailDomain = false;
		managedProviderFound = false;
		discoRes = null;
		const email = value;

		if (timer) clearTimeout(timer);

		if (!isValidEmail(value)) return;

		timer = setTimeout(async () => {
			if (!email.length) return;
			checkManagedAjax = true;
			try {
				discoRes = await getManagedDicsovery(email);
				if (email !== value) return;
				if (discoRes.error === 'PUBLIC_EMAIL_DOMAIN') {
					isPublicEmailDomain = true;
				} else if (discoRes.error === 'NO_PROVIDER_FOUND') {
					showManagedNotFoundErrorText = true;
				} else {
					managedProviderFound = true;
				}
			} catch (err) {
				managedProviderFound = false;
				console.error(err);
			}
			checkManagedAjax = false;
		}, 650);
	}

	async function continueWithProvider(slug) {
		try {
			$showSpinner = true;
			const body = {
				accountSelected,
				// accountToUse: 'managed',
				managedRequest: true,
				login_hint: value || undefined //dont send empty login_hint
			};
			const { redirect } = login
				? await postLoginProvider({ slug, body })
				: await postLinkProvider({ slug, body });
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}
</script>

<div class="w-full {css}" data-test="managed-provider">
	<!-- svelte-ignore a11y-autofocus -->
	<input
		autofocus
		type="text"
		name="managed-email"
		bind:value
		on:input={handleInput}
		id="managed-email"
		autocapitalize="off"
		placeholder="enter your managed email address"
		class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
	/>
	{#if !managedProviderFound}
		<div class="h-16 flex items-center justify-center">
			{#if showManagedNotFoundErrorText || isPublicEmailDomain}
				<span class=" mt-2 block text-center" data-test="managed-input-error">
					<span class="font-semibold">{domain}</span><br />
					{#if showManagedNotFoundErrorText}
						<span class="text-red-500">{$_('could not find provider')}</span>
					{:else if isPublicEmailDomain}
						<span class="text-red-500">{$_('not from a managed provider')}</span>
					{/if}
				</span>
			{:else if checkManagedAjax}
				<SpinnerIcon css="w-5 h-5" />
			{:else}
				<span class="my-4 block uppercase">{$_('Or')}</span>
			{/if}
		</div>
	{/if}
	{#if !isPublicEmailDomain}
		<div class="flex flex-col items-start" class:mt-1={managedProviderFound} transition:slide|local>
			{#if !managedProviderFound}
				<span>{$_('Select a provider')}</span>
			{/if}

			<div class="w-full mt-2">
				{#if managedProviderFound}
					<div transition:slide|local>
						<LoginProvider
							on:click={() => continueWithProvider(discoRes.managed.slug)}
							logo={discoRes.managed.logo}
							prefix="Continue with"
							managed={true}
							provider={{
								slug: discoRes.managed.logo ? '' : discoRes.managed.slug,
								user_name: value
							}}
						/>
					</div>
				{:else}
					<div class="space-y-2">
						{#each logins.filter((i) => i.managed) as provider}
							<LoginProvider
								on:click={() => continueWithProvider(provider.slug)}
								disabled={checkManagedAjax}
								prefix="Continue with"
								{provider}
							/>
						{/each}

						{#if showManagedNotFoundErrorText}
							<div
								data-test="verify-email-managed"
								class="relative h-auto btn-border rounded-md hover-none px-4 pt-4"
								transition:slide|local
							>
								<button class="mb-2 w-full flex items-center justify-start">
									<MailIcon size="lg" />
									<div
										class="flex flex-col {$locale && $locale.startsWith('ar')
											? 'text-right mr-4'
											: 'text-left ml-4'}"
									>
										<span>
											{$_('Continue with Email')}
										</span>
										<span>{value}</span>
									</div>
								</button>

								<div class="pb-4 pt-1">
									<VerifyEmail
										{login}
										email={value}
										disabled
										accountToUse="managed"
										on:success={() => dispatch('managedEmailSuccess')}
									/>
								</div>
							</div>
						{/if}
					</div>
				{/if}
			</div>
		</div>
	{/if}
</div>
