<script>
	import { createEventDispatcher } from 'svelte';
	import { slide } from 'svelte/transition';
	export let notifications = [];
	import { _ } from 'svelte-i18n';

	const dispatch = createEventDispatcher();

	const trimEthAddress = (addr) => {
		return addr.slice(0, 6) + '...' + addr.slice(38);
	};
</script>

<div
	class="fixed z-[200] inset-0 overflow-y-auto"
	aria-labelledby="modal-title"
	role="dialog"
	aria-modal="true"
>
	<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
		<div
			on:click={() => dispatch('cancel')}
			class="fixed inset-0 bg-charcoal bg-opacity-80 transition-opacity"
			aria-hidden="true"
		/>
		<span class="hidden inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
		<div
			class="modal relative inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-sm w-full"
		>
			<ul class="space-y-0.5">
				{#each notifications as notification}
					{#if notification.text}
						<li
							class="{notification.type === 'error'
								? 'bg-red-500'
								: 'bg-green-500'} text-sm text-charcoal text-center py-1.5"
							transition:slide|local
						>
							{notification.text}
						</li>
					{/if}
				{/each}
			</ul>
			<div class="h-36 flex items-center justify-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				{#if notifications[notifications.length - 1].status.startsWith('0x')}
					<div class="text-center w-full">
						{$_('Sign with {address}', {
							values: {
								address: trimEthAddress(notifications[notifications.length - 1].status)
							}
						})}

						<div class="mt-6 flex items-center justify-between w-full w-56 mx-auto">
							<button on:click={() => dispatch('cancel')} class="btn-border h-8 text-sm w-20">
								{$_('Cancel')}
							</button>

							<button
								on:click={() => dispatch('ok', notifications[notifications.length - 1].status)}
								class="btn-border h-8 text-sm w-20"
							>
								{$_('OK')}
							</button>
						</div>
					</div>
				{:else}
					<div class="text-center text-charcoal dark:text-[#D4D4D4]">
						<svg
							id="spinner"
							style="height: 2.4rem; width: 2.4rem"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							class="mx-auto"
						>
							<circle
								style="opacity: 0.5"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="3"
							/>
							<path
								opacity="0.5"
								d="M2 12C2 6.47715 6.47715 2 12 2"
								stroke="black"
								stroke-width="3"
							/>
						</svg>
						<span class="mt-5 block">
							{notifications[notifications.length - 1].status}
						</span>
					</div>
				{/if}
			</div>
		</div>
	</div>
</div>
