<script>
	export let size = 'md';
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	class:w-4.5={size === 'md'}
	class:max-h-[18px]={size === 'md'}
	class:w-9={size === 'lg'}
	class:max-h-[36px]={size === 'lg'}
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
	stroke-width="2"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
	/>
</svg>
