<script>
	import ChevronDownIcon from './icon/ChevronDownIcon.svelte';

	let hover = false;
	export let expanded = false;
	export let ariaLabel = '';
	export let id = undefined;
	export let dataTest = undefined;
	export let disabled = false;
	export let hasBackground = true;
	export let cssClass = '';
	export let hideControl = false;
</script>

<div
	{id}
	class="relative w-full h-auto rounded-md {cssClass}"
	class:btn-background={!expanded && hasBackground}
	class:box-shadow-hover={hover || (expanded && hasBackground)}
	class:box-shadow={!hasBackground}
	class:opacity-50={disabled}
	class:cursor-not-allowed={disabled}
>
	<button
		data-test={dataTest}
		aria-label={ariaLabel}
		on:mouseenter={() => (hover = true)}
		on:mouseleave={() => (hover = false)}
		on:focus={() => (hover = true)}
		on:blur={() => (hover = false)}
		on:click
		{disabled}
		class="absolute z-20 h-12 flex items-center justify-end px-4 w-full"
		class:invisible={hideControl}
	>
		<ChevronDownIcon rotate={expanded} {hover} />
	</button>

	<slot />
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}

		.box-shadow-hover:not(:disabled) {
			box-shadow: 0 0 0 2px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}

		.box-shadow-hover:not(:disabled) {
			box-shadow: 0 0 0 2px #303030;
		}
	}
</style>
